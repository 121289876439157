.timeline-contain {
  padding: 2rem;
  background-color: white;
}

.values-contain {
  padding: 2rem;
  background-color: #191919 !important;
}

.nosotros {
  font-family: 'Permanent Marker', cursive !important;
  font-size: 3rem !important;
  letter-spacing: 1rem;
  padding: 1rem;
  border-bottom: 3px solid #F9AB1B;
  width: fit-content;
  color: #fff !important;
  margin: 1rem auto;
}

.titulo2 {
  font-family: 'Permanent Marker', cursive !important;
  font-size: 3rem !important;
  letter-spacing: 1rem;
  padding: 1rem;
  border-bottom: 3px solid #53C6D4;
  width: fit-content;
  margin: 1rem auto;
}

.timeline-box {
  padding-top: 2rem !important;
  width: 70vw;
  margin: auto !important;
}

.ant-timeline-item-label {
  font-weight: bold;
}

.timeline-img {
  width: 30vw;
  margin: auto;
}

.list-value {
  width: 40vw;
  text-align: center;
  margin: 1rem auto !important;
}

.ant-list-item-meta-title {
  color: white !important;
}

.portfolio-pa {
  width: 40vw;
  text-align: justify;
  margin: 2rem auto;
  padding: 1rem;
}

.portfolio-pa {
  span {
    color: white !important;
    font-weight: bold;
  }
}

.slide-listt {
  width: 50vw;
  margin: auto !important;

  .ant-list-item {
    color: #fff !important;
    letter-spacing: .3rem;
  }
}

@media screen and (max-width: 414px) {
  .timeline-box {
    width: 90vw;
  }
  .timeline-img {
    width: 80vw;
  }
  .list-value {
    width: 80vw;
  }
  .portfolio-pa {
    width: 60vw;
  }
  .slide-listt {
    width: 80vw;
  }
}
