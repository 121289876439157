body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.timeline-contain {
  padding: 2rem;
  background-color: white;
}
.values-contain {
  padding: 2rem;
  background-color: #191919 !important;
}
.nosotros {
  font-family: 'Permanent Marker', cursive !important;
  font-size: 3rem !important;
  letter-spacing: 1rem;
  padding: 1rem;
  border-bottom: 3px solid #F9AB1B;
  width: fit-content;
  color: #fff !important;
  margin: 1rem auto;
}
.titulo2 {
  font-family: 'Permanent Marker', cursive !important;
  font-size: 3rem !important;
  letter-spacing: 1rem;
  padding: 1rem;
  border-bottom: 3px solid #53C6D4;
  width: fit-content;
  margin: 1rem auto;
}
.timeline-box {
  padding-top: 2rem !important;
  width: 70vw;
  margin: auto !important;
}
.ant-timeline-item-label {
  font-weight: bold;
}
.timeline-img {
  width: 30vw;
  margin: auto;
}
.list-value {
  width: 40vw;
  text-align: center;
  margin: 1rem auto !important;
}
.ant-list-item-meta-title {
  color: white !important;
}
.portfolio-pa {
  width: 40vw;
  text-align: justify;
  margin: 2rem auto;
  padding: 1rem;
}
.portfolio-pa span {
  color: white !important;
  font-weight: bold;
}
.slide-listt {
  width: 50vw;
  margin: auto !important;
}
.slide-listt .ant-list-item {
  color: #fff !important;
  letter-spacing: 0.3rem;
}
@media screen and (max-width: 414px) {
  .timeline-box {
    width: 90vw;
  }
  .timeline-img {
    width: 80vw;
  }
  .list-value {
    width: 80vw;
  }
  .portfolio-pa {
    width: 60vw;
  }
  .slide-listt {
    width: 80vw;
  }
}
